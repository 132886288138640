import React, { Component } from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Contact from '@sections/Contact';
import Footer from '@sections/Footer';

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <Navbar />
        <Contact />
        <Footer />
      </Layout>
    );
  }
}
export default ContactPage;
